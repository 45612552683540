<template>
  <v-card-text class="k-contract">
    <div class="c16">
        <p >
          <span >A presente pol&iacute;tica de privacidade tem por objetivo esclarecer a
              todos os usu&aacute;rios do </span><span >site </span><span >do Simplificador de
              que modo e, em que limites, ocorre o tratamento de
              seus dados pessoais, com a m&aacute;xima transpar&ecirc;ncia e respeito
              &agrave; privacidade.</span>
          </p>
          <p >
            <span >As pr&aacute;ticas do Simplificador est&atilde;o adequadas ao disposto
                na Lei 13.709/2018 a Lei Geral de Prote&ccedil;&atilde;o de Dados
                (LGPD), cujo inteiro teor pode ser acessado pelo </span><span class="c0 c3"><a class="c10"
                    href="https://www.google.com/url?q=http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/Lei/L13709.htm&amp;sa=D&amp;source=editors&amp;ust=1617655926018000&amp;usg=AOvVaw0eOxoJkB0M9RP-Uhgg8Bpc">link</a></span><span
                >, que &eacute; a Lei que disp&otilde;e, no territ&oacute;rio nacional,
                acerca da prote&ccedil;&atilde;o dos dados pessoais.</span>
          </p>

          <p >
              <span >Para fins deste documento, entende-se por &ldquo;Controlador de
                  Dados&rdquo; o Simplificador, por &ldquo;Titular de Dados&rdquo; o
                  usu&aacute;rio do </span><span >site</span><span >, e por &ldquo;tratamento de
                  dados&rdquo; todas as a&ccedil;&otilde;es
                  poss&iacute;veis com dados do Titular (coleta, armazenamento,
                  manipula&ccedil;&atilde;o, dele&ccedil;&atilde;o, etc.).</span>
          </p>

          <p ><strong >1 &ndash; DO SIMPLIFICADOR</strong></p>
          <p >
              <span >O Simplificador &eacute; uma plataforma de contabilidade online, que
                  atende exclusivamente: prestadores de servi&ccedil;os; MEIs ou Simples
                  Nacional; sem funcion&aacute;rios registrados; com CNPJ da cidade de
                  S&atilde;o Paulo.</span>
          </p>
          <p >
              <span >A miss&atilde;o do Simplificador &eacute; atender de forma
                  r&aacute;pida, din&acirc;mica e com todos os respaldos legais, e por
                  meio de profissionais credenciados, as pessoas jur&iacute;dicas que
                  precisam de uma contabilidade sem complica&ccedil;&atilde;o, com a
                  m&aacute;xima qualidade e pre&ccedil;os acess&iacute;veis.</span>
          </p>


          <p >
              <strong class="c8 c5">2 - FINALIDADE DO SITE DO SIMPLIFICADOR</strong>
          </p>

          <p >
            <span >O </span><span >site </span><span >do Simplificador tem por
                finalidade a apresenta&ccedil;&atilde;o aos
                usu&aacute;rios das informa&ccedil;&otilde;es institucionais do
                Simplificador, dos servi&ccedil;os prestados, com o m&aacute;ximo
                detalhamento, e a contrata&ccedil;&atilde;o de tais servi&ccedil;os.
                Para os clientes do Simplificador, o </span><span >site </span><span >oferece
                tamb&eacute;m uma &aacute;rea de acesso restrito
                (&ldquo;&aacute;rea logada&rdquo;), em que &eacute; poss&iacute;vel
                acompanhar a evolu&ccedil;&atilde;o dos servi&ccedil;os prestados,
                dentre outras finalidades.</span>
          </p>

          <p >
              <strong class="c8 c5 c9">3 &ndash; O SIMPLIFICADOR PRECISA DOS MEUS DADOS PESSOAIS?</strong>
          </p>

          <p >
              <span >Sim. O Simplificador necessita de alguns de seus dados pessoais para
                  (i) envio de </span><span >newsletter, </span><span >caso voc&ecirc; solicite,
                  (ii) completar o seu cadastro, caso tenha
                  interesse nos servi&ccedil;os do simplificador e (iii) prestar os
                  servi&ccedil;os para os quais for contratado. Em qualquer
                  hip&oacute;tese, o Simplificador apenas tratar&aacute; os dados
                  estritamente necess&aacute;rios para tais atividades, nos termos de sua
                  pol&iacute;tica de privacidade interna e diretrizes de seguran&ccedil;a
                  da informa&ccedil;&atilde;o.</span>
          </p>


          <p >
              <strong class="c8 c5">4 &ndash; O SITE PERMITE A  NAVEGA&Ccedil;&Atilde;O AN&Ocirc;NIMA?</strong>
          </p>

          <p >
              <span >Sim. O </span><span >site </span><span >do Simplificador &eacute;
                  amig&aacute;vel para a
                  navega&ccedil;&atilde;o an&ocirc;nima, e tamb&eacute;m para a
                  navega&ccedil;&atilde;o com parametriza&ccedil;&atilde;o de </span>cookiespelo usu&aacute;rio, em seu navegador. Contudo, a
                  navega&ccedil;&atilde;o an&ocirc;nima, ou sem </span><span >cookies, </span><span
                  >pode prejudicar a sua experi&ecirc;ncia de navega&ccedil;&atilde;o
                  (consulte nossa </span><span class="c6 c5 c17">pol&iacute;tica de cookies).</span>
          </p>

          <p >
              <strong class="c8 c5">5 &ndash; A NAVEGA&Ccedil;&Atilde;O NO SITE, N&Atilde;O LOGADA,&nbsp;IMPLICA NO TRATAMENTO DE MEUS DADOS PESSOAIS?</strong>
          </p>

          <p >
              <span >Ao acessar www.simplificador.com.br</span><span >, </span><span
                  >com o navegador sem restri&ccedil;&atilde;o de navega&ccedil;&atilde;o,
                  seu navegador trocar&aacute;, automaticamente, informa&ccedil;&otilde;es
                  com o </span><span >site </span><span >&nbsp;(</span><span >cookies,
                  tags, beacons, </span><span >e tecnologias semelhantes), al&eacute;m de informar o seu
                  endere&ccedil;o IP (internet protocol), caracter&iacute;sticas do
                  dispositivo de acesso, navegador, informa&ccedil;&otilde;es sobre
                  cliques, p&aacute;ginas e telas que foram acessadas, termos procurados
                  dentro do site e o fluxo de navega&ccedil;&atilde;o dentro do seu
                  s&iacute;tio eletr&ocirc;nico.</span>
          </p>
          <p >
              <span >Mas fique tranquilo: Essas informa&ccedil;&otilde;es tem por objetivo a
                  customiza&ccedil;&atilde;o do </span><span >site </span><span >e eventuais
                  an&uacute;ncios para melhorar a sua experi&ecirc;ncia. De
                  modo algum o Simplificador ter&aacute; acesso ao seu nome e
                  comportamentos, ou seja, estas informa&ccedil;&otilde;es captadas
                  n&atilde;o permitem que voc&ecirc; seja identificado. O Simplificador
                  utiliza as tecnologias j&aacute; existentes no mercado, adequadas
                  &agrave;s melhores pr&aacute;ticas, e pouco invasivas, tendo como
                  &uacute;nica finalidade a melhora da sua experi&ecirc;ncia de
                  navega&ccedil;&atilde;o.</span>
          </p>

          <p >
              <strong class="c8 c5">6 &ndash; A NAVEGA&Ccedil;&Atilde;O NA &Aacute;REA LOGADA, &nbsp; IMPLICA NO TRATAMENTO DE MEUS DADOS PESSOAIS?</strong>
          </p>

          <p >
              <span >Sim. Para o cadastro e posterior log in no </span><span >site, </span><span
                  >e no curso da presta&ccedil;&atilde;o dos servi&ccedil;os, &eacute;
                  necess&aacute;rio que o usu&aacute;rio forne&ccedil;a alguns dados
                  pessoais, tais como nome completo, endere&ccedil;o f&iacute;sico
                  (logradouro, n&uacute;mero, bairro, Cidade, UF, CEP), endere&ccedil;o
                  eletr&ocirc;nico, CPF, Identidade, telefone para contato, e, quando
                  houver necessidade, dados que necessitem oportunamente serem preenchidos
                  em formul&aacute;rios espec&iacute;ficos.</span>
          </p>
          <p >
              <span >Durante, e em decorr&ecirc;ncia da presta&ccedil;&atilde;o de
                  servi&ccedil;os, &eacute; poss&iacute;vel que o Simplificador venha a
                  ter acesso a outros dados pessoais do cliente, que ser&atilde;o tratados
                  de acordo com a pol&iacute;tica de privacidade do Simplificador, sempre
                  garantida a m&aacute;xima prote&ccedil;&atilde;o &agrave; privacidade, e
                  o pleno exerc&iacute;cio dos direitos dos titulares de dados.</span>
          </p>
          <p class="c1 c13"><span >&nbsp;</span></p>
          <p >
              <strong >7 &ndash; O SIMPLIFICADOR COMPARTILHA MEUS DADOS PESSOAIS?</strong>
          </p>
          <p class="c11"><span >&nbsp;</span></p>
          <p >
              <span >O Simplificador n&atilde;o compartilha os seus dados pessoais, salvo
                  nas seguintes e exclusivas hip&oacute;teses: (i) para viabilizar a
                  presta&ccedil;&atilde;o dos servi&ccedil;os contratados, e atendimento
                  de normas ou regulamentos (Art. 7, incisos II e III da Lei 13.709/2018);
                  (ii) prote&ccedil;&atilde;o dos interesses do Simplificador em
                  qualquer tipo de conflito (Art. 7, VI e IX e Art. 10 da Lei
                  13.709/2018); ou (iii) mediante decis&atilde;o judicial ou
                  requisi&ccedil;&atilde;o de autoridades competentes (Art. 7, incisos III
                  e VI da Lei 13.709/2018).</span>
          </p>
          <p >
              <span >O Simplificador poder&aacute; excepcionalmente utilizar os dados para
                  fins de intelig&ecirc;ncia de neg&oacute;cio e opera&ccedil;&otilde;es
                  estritamente necess&aacute;rias ao seu desenvolvimento, garantindo
                  previamente sua anonimiza&ccedil;&atilde;o ou ado&ccedil;&atilde;o de
                  medida similar.</span>
          </p>

          <p >
              <strong >8 &ndash; COMO FA&Ccedil;O PARA REVISAR MEUS DADOS CADASTRAIS, SANAR
                  D&Uacute;VIDAS ACERCA DO TRATAMENTO DE MEUS DADOS, OU PEDIR PARA
                  EXCLUIREM MEUS DADOS?</strong>
          </p>
          <p >
              <span >O Simplificador garante aos titulares o pronto e pleno exerc&iacute;cio
                  de todos os seus direitos. Voc&ecirc; poder&aacute; requerer o acesso,
                  atualiza&ccedil;&atilde;o, adi&ccedil;&atilde;o ou
                  retifica&ccedil;&atilde;o de seus dados, bem como poder&aacute;
                  solicitar a exclus&atilde;o dos dados coletados pelo Simplificador,
                  bastando para tanto o log in na &ldquo;&aacute;rea do cliente&rdquo;, ou
                  por meio de contato com Matheus Solidade, </span><span class="c4">Encarregado de Dados (DPO)
              </span><span >&nbsp;no seguinte e-mail: </span>
              <span >
              <a href="matheus:privacidade@simplificador.com.br">privacidade@simplificador.com.br</a>

              </span>
          </p>


          <p >
              <span >Para facilitar o seu atendimento, favor colocar como assunto do e-mail
                  o termo &ldquo;privacidade de dados&rdquo;, e descrever no corpo do
                  e-mail detalhadamente seu pedido, que ser&aacute; respondido no menor
                  tempo poss&iacute;vel, observando a legisla&ccedil;&atilde;o
                  concernente.</span>
          </p>
          <p >
              <span >O Simplificador poder&aacute; adotar medidas para garantir a
                  autenticidade e legitimidade do pedido formulado por este canal,
                  garantindo o retorno com a maior brevidade poss&iacute;vel.</span>
          </p>

          <p >
              <strong >9 &ndash; O QUE S&Atilde;O COOKIES E TECNOLOGIAS SEMELHANTES?</strong>
          </p>
          <p >
              <span >Cookies s&atilde;o pequenos arquivos trocados entre o s&iacute;tio
                  eletr&ocirc;nico e o seu navegador, que ser&atilde;o armazenados no seu
                  computador ou dispositivo m&oacute;vel, que servem como indicativos de
                  comportamento de navega&ccedil;&atilde;o, visando otimizar sua interface
                  com o </span><span >site</span><span >, salvar prefer&ecirc;ncias, etc.
                  Al&eacute;m dos </span><span >cookies, </span><span >existem diversas outras
                  tecnologias semelhantes, e com a mesma
                  finalidade: </span><span >beacons, tags, </span><span >etc.</span>
          </p>
          <p >
              <span >Os </span><span >cookies </span><span >podem ser de sess&atilde;o
                  (permanecem ativos at&eacute; que a
                  p&aacute;gina ou o navegador seja fechado) ou persistentes (permanecem
                  ap&oacute;s o fechamento da p&aacute;gina ou do navegador, sendo
                  utilizados, p. ex., para relembrar o log in dos usu&aacute;rios). Podem
                  ser tamb&eacute;m propriet&aacute;rios (definidos pelo pr&oacute;prio </span><span
                  >site</span><span >), ou de terceiros (como o google analytics, e
                  facebook).</span>
          </p>
          <p >
              <span >As informa&ccedil;&otilde;es obtidas pelo </span><span >site, </span><span
                  >ajudam a entender o comportamento de navega&ccedil;&atilde;o dos
                  usu&aacute;rios no </span><span >site </span><span >(desde o acesso, at&eacute;
                  a conclus&atilde;o do uso), indicando quais
                  p&aacute;ginas naquele </span><span >site </span><span >foram acessadas, quais
                  imagens ou textos apresentaram o melhor
                  engajamento, etc. (cookies &ldquo;necess&aacute;rios&rdquo;), permitem
                  ao </span><span >site</span><span >&nbsp;lembrar as escolhas feitas pelo
                  usu&aacute;rio, tornando mais
                  eficiente a navega&ccedil;&atilde;o futura (cookies
                  &ldquo;funcionais&rdquo;) e at&eacute; a parametriza&ccedil;&atilde;o de
                  conte&uacute;do (cookies de &ldquo;marketing&rdquo;).</span>
          </p>

          <p >
              <strong class="c8 c5">10 &ndash; COMO EU POSSO FAZER PARA QUE UM SITE N&Atilde;O TROQUE COOKIES COM O MEU NAVEGADOR?</strong>
          </p>
          <p >
              <span >O usu&aacute;rio do </span><span >site </span><span >pode optar
                  pela navega&ccedil;&atilde;o an&ocirc;nima, bloqueio e
                  exclus&atilde;o de </span><span >cookies </span><span >diretamente em seu
                  navegador. Caso queira aprender a bloquear os </span><span >cookies</span><span
                  >&nbsp;em seu navegador (Chrome, Internet Explorer, Firefox, Edge,
                  Safari), voc&ecirc; pode clicar </span><span class="c3 c5"><a class="c10"
                      href="https://www.google.com/url?q=https://blog.simplificador.com.br/dica-de-navegacao-em-sites-sabe-como-ativar-ou-desativar-cookies-nos-principais-navegadores/&amp;sa=D&amp;source=editors&amp;ust=1617655926023000&amp;usg=AOvVaw1u9uxd0tFDwrXAITCepjD1">aqui</a></span><span
                  >.</span>
          </p>
          <p >
              <span class="c5 c15">&nbsp;</span><span >&nbsp;</span>
          </p>
          <p >
              <strong >11 &ndash; POSSUO UMA CONTA GOOGLE OU FACEBOOK. O SIMPLIFICADOR VAI FICAR SABENDO DISSO?</strong>
          </p>
          <p >
              <span >N&atilde;o. A integra&ccedil;&atilde;o de cookies de terceiro no </span><span
                  >site </span><span >tem finalidade anal&iacute;tica. O Simplificador somente
                  ter&aacute;
                  acesso a relat&oacute;rios indicando que um determinado n&uacute;mero de
                  pessoas </span><span class="c5 c19">n&atilde;o identificadas para o Simplificador</span><span
                  >, com determinadas caracter&iacute;sticas (idade, g&ecirc;nero, cidade
                  de acesso, por exemplo), acessou o </span><span >site</span><span >&nbsp;e se
                  comportou de determinadas formas. Por sua vez o terceiro
                  (Google, Facebook, etc.) receber&aacute; a informa&ccedil;&atilde;o que
                  voc&ecirc; acessou o </span><span >site </span><span >do Simplificador, e
                  eventualmente utilizar&aacute; essa
                  informa&ccedil;&atilde;o de acordo com a pr&oacute;pria
                  intelig&ecirc;ncia de neg&oacute;cios e pr&oacute;pria pol&iacute;tica
                  de privacidade.</span>
          </p>
          <p >
              <span >Caso voc&ecirc; tenha d&uacute;vidas de como estas empresas tratam os
                  seus dados, voc&ecirc; pode consultar as pol&iacute;ticas de privacidade
                  espec&iacute;ficas:</span>
          </p>
          <p >
              <span class="c8 c5">Google:</span><span class="c5 c8"><a class="c10"
                      href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Dpt-BR&amp;sa=D&amp;source=editors&amp;ust=1617655926024000&amp;usg=AOvVaw1FJZl-l4YenpQZIJ7RDfOJ">&nbsp;</a></span><span
                  class="c3 c5"><a class="c10"
                      href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Dpt-BR&amp;sa=D&amp;source=editors&amp;ust=1617655926024000&amp;usg=AOvVaw1FJZl-l4YenpQZIJ7RDfOJ">https://policies.google.com/privacy?hl=pt-BR</a></span>
          </p>
          <p >
              <span class="c8 c5">Facebook:</span><span class="c8 c5"><a class="c10"
                      href="https://www.google.com/url?q=https://www.facebook.com/policies/ads&amp;sa=D&amp;source=editors&amp;ust=1617655926024000&amp;usg=AOvVaw1FIfAEKSD6Fz2m96RO_OC8">&nbsp;</a></span><span
                  class="c3 c5"><a class="c10"
                      href="https://www.google.com/url?q=https://www.facebook.com/policies/ads&amp;sa=D&amp;source=editors&amp;ust=1617655926024000&amp;usg=AOvVaw1FIfAEKSD6Fz2m96RO_OC8">https://www.facebook.com/policies/ads</a></span>
          </p>

          <p >
              <strong >12 &ndash; O SIMPLIFICADOR UTILIZA COOKIES E TECNOLOGIAS SEMELHANTES?</strong>
          </p>

          <p >
              <span >O Simplificador n&atilde;o utiliza nenhum cookie que possa ser
                  considerado invasivo. &nbsp;Mas caso voc&ecirc; n&atilde;o sinta
                  confort&aacute;vel, recomendamos que utilize a op&ccedil;&atilde;o de
                  navega&ccedil;&atilde;o an&ocirc;nima, ou bloqueie os cookies em seu
                  navegador (vide item 10).</span>
          </p>
          <p >
              <span >&Eacute; importante ressaltar que o usu&aacute;rio correr&aacute; o
                  risco de n&atilde;o receber adequadamente todas as ofertas, campanhas ou
                  funcionalidades que s&atilde;o disponibilizadas a partir de sua
                  navega&ccedil;&atilde;o.</span>
          </p>

          <p >
              <strong >13 &ndash; CONTRATEI O SIMPLIFICADOR E PAGUEI ON-LINE. O SIMPLIFICADOR GUARDA OS DADOS DO CART&Atilde;O OU DE PAGAMENTO?</strong>
          </p>

          <p >
              <span >O Simplificador n&atilde;o solicita dados de pagamento. Todos os
                  pagamentos s&atilde;o feitos em ambiente de terceiro, a IUGU
                  Servi&ccedil;os da Internet S.A., inscrita no CNPJ sob o n&ordm;
                  15.111.975/0001-64, em ambiente pr&oacute;prio da IUGU. Para conferir a
                  pol&iacute;tica de privacidade da IUGU, favor acessar o </span><span >link</span><span
                  ><a class="c10"
                      href="https://www.google.com/url?q=https://www.iugu.com/juridico/politica-de-privacidade/&amp;sa=D&amp;source=editors&amp;ust=1617655926025000&amp;usg=AOvVaw17LoPVFpLzdwy3ly1hgAif">&nbsp;</a></span><span
                  class="c3 c5"><a class="c10"
                      href="https://www.google.com/url?q=https://www.iugu.com/juridico/politica-de-privacidade/&amp;sa=D&amp;source=editors&amp;ust=1617655926025000&amp;usg=AOvVaw17LoPVFpLzdwy3ly1hgAif">https://www.iugu.com/juridico/politica-de-privacidade/</a></span>
          </p>
          <p class="c11"><span >&nbsp;</span></p>

          <p >
              <strong >14 &ndash; QUAIS MEDIDAS O SIMPLIFICADOR ADOTA PARA A SEGURAN&Ccedil;A DOS MEUS DADOS?</strong>
          </p>

          <p >
              <span >O Simplificador atende &agrave;s regras de boas pr&aacute;ticas no que
                  tange &agrave; prote&ccedil;&atilde;o de dados pessoais e
                  seguran&ccedil;a da informa&ccedil;&atilde;o, desde a
                  implementa&ccedil;&atilde;o de regras de acesos, at&eacute; a
                  utiliza&ccedil;&atilde;o de tecnologias sofisticadas e condizentes com a
                  criticidade dos dados tratados, sua finalidade e escopo dos
                  servi&ccedil;os, tais como:</span>
          </p>

          <p class="c1 c7">
              <span class="c4">i)</span><span class="c14">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;</span><span >O uso de criptografias de acesso tamb&eacute;m &eacute; demonstrado,
                  tal como o padr&atilde;o SSL &ndash; </span><span >Secure Sockets Layer</span><span
                  >, configurado como site seguro para acesso;</span>
          </p>
          <p class="c1 c13"><span >&nbsp;</span></p>
          <p class="c1 c7">
              <span class="c4">ii)</span><span class="c14">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </span><span >Prote&ccedil;&atilde;o contra acesso n&atilde;o autorizado aos seus
                  sistemas;</span>
          </p>
          <p class="c11"><span >&nbsp;</span></p>
          <p class="c1 c7">
              <span class="c4">iii)</span><span class="c14">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span
                  >Armazenamento de dados e documentos em reposit&oacute;rio de
                  m&aacute;xima confian&ccedil;a, com regras de </span><span >back up</span><span
                  >&nbsp;f&iacute;sicas e digitais.</span>
          </p>

          <p >
              <span >O Simplificador mant&eacute;m constantes investimentos em tecnologia e
                  seguran&ccedil;a da informa&ccedil;&atilde;o, e adota todas as
                  precau&ccedil;&otilde;es e princ&iacute;pios estabelecidos na ISO/IEC
                  27001 e 27002, em observ&acirc;ncia a padr&otilde;es estabelecidos no
                  Art. 13, do Decreto n&ordm;. 8.771/2016 e ao Art. 6, inciso VII, da Lei
                  13.709.</span>
          </p>

          <p >
              <strong >15 &ndash; POR QUANTO TEMPO O SIMPLIFICADOR ARMAZENA OS MEUS
                  DADOS?</strong>
          </p>

          <p >
              <span >Os dados pessoais dos usu&aacute;rios do </span><span >site </span><span
                  >n&atilde;o clientes ser&atilde;o eliminados automaticamente ap&oacute;s
                  atingido o ciclo de vida (cookies e tecnologias semelhantes), ou
                  solicitada a elimina&ccedil;&atilde;o (inclusive </span><span >newsletter</span><span
                  >).</span>
          </p>
          <p >
              <span >Os dados pessoais dos clientes permanecer&atilde;o nos bancos de dados
                  do Simplificador enquanto durar a presta&ccedil;&atilde;o dos
                  servi&ccedil;os, acrescido de prazo de seguran&ccedil;a considerando as
                  obriga&ccedil;&otilde;es legais e contratuais decorrentes, conforme
                  pol&iacute;tica de privacidade, exceto </span><span >newsletter</span><span
                  >.</span>
          </p>
          <p >
              <span >O Simplificador poder&aacute; anonimizar os dados, dede que garanta a
                  irreversibilidade desta medida.</span>
          </p>

          <p >
              <strong >16 &ndash; QUAL A BASE LEGAL PARA O TRATAMENTO DOS MEUS DADOS?</strong>
          </p>
          <p >
              <span >O tratamento dos dados pessoais pelo Simplificador se d&aacute;
                  eminentemente de acordo com 3 bases legais: (i) Execu&ccedil;&atilde;o
                  de contratos, assim considerando a necessidade de tratamento para o
                  preenchimento de formul&aacute;rios, guias, instrumentos contratuais,
                  etc. e informa&ccedil;&otilde;es aos &Oacute;rg&atilde;os
                  P&uacute;blicos, nos exatos termos dos servi&ccedil;os contratados; (ii)
                  Leg&iacute;timo interesse do Simplificador, assim entendidas as
                  a&ccedil;&otilde;es que visam fomentar os neg&oacute;cios
                  pr&oacute;prios do Simplificador; e excepcionalmente o (iii)
                  Consentimento, nos casos em que o Simplificador solicite o consentimento
                  expresso do usu&aacute;rio/cliente, para finalidade
                  espec&iacute;fica.</span>
          </p>



          <div>
              <p class="c12"><span >Vers&atilde;o 1, de 01/04/2021</span></p>


          </div>
    </div>
  </v-card-text>
</template>
<script>
export default {
  name: 'agree-privice-police',
  data() {
    return {}
  },
}
</script>
<style lang="sass" scoped >
.k-contract
  h1
    font-size: 20px
    margin-bottom: 5px

    h2
      font-size: 16px
      margin-bottom: 5px

      .k-contract-title
        text-transform: uppercase</style>
